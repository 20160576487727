import { RouteObject } from 'react-router-dom';
import ZoneDetails from '@/pages/zones/slug';
import Error404 from '@/components/error/404';

export const zonesRoutes: RouteObject[] = [
  {
    path: 'zone/:slug',
    errorElement: <Error404 />,
    element: <ZoneDetails />,
  },
  {
    path: 'zones/:slug',
    errorElement: <Error404 />,
    element: <ZoneDetails />,
  },
];
