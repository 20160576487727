import { motion } from 'framer-motion';

const COMMON = {
  initial: { fill: '#75757c' },
  animate: { fill: '#5c5d64' },
};

const LogoGraySpinner = ({ className = '' }) => {
  return (
    <motion.svg
      width='241'
      height='241'
      viewBox='0 0 241 241'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M240.122 127.342C236.523 190.441 184.173 240.5 120.122 240.5C56.0705 240.5 3.72039 190.441 0.121582 127.342H61.0253C64.4657 156.905 89.6117 179.849 120.122 179.849C150.632 179.849 175.778 156.905 179.218 127.342H240.122Z'
        {...COMMON}
        transition={{ repeat: Infinity, repeatDelay: 0.6 }}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.4605 96.8833C63.2225 102.069 61.7033 107.636 61.0253 113.46H0.121582C1.08158 96.6279 5.51079 80.7242 12.7048 66.45L65.4605 96.8833Z'
        {...COMMON}
        transition={{ delay: 0.2, repeat: Infinity, repeatDelay: 0.6 }}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M84.558 72.7363C79.9512 76.1738 75.8592 80.2625 72.4188 84.8667L19.6643 54.4333C28.6775 40.7574 40.4135 29.0311 54.1008 20.0253L84.558 72.7363Z'
        {...COMMON}
        transition={{ delay: 0.3, repeat: Infinity, repeatDelay: 0.6 }}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M113.174 0.499817V61.3533C107.346 62.0307 101.774 63.5487 96.5842 65.7849L66.1257 13.0727C80.4106 5.88458 96.3274 1.45903 113.174 0.499817Z'
        {...COMMON}
        transition={{ delay: 0.4, repeat: Infinity, repeatDelay: 0.6 }}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M174.118 13.0727L143.659 65.7849C138.469 63.5487 132.899 62.0307 127.071 61.3533V0.499817C143.915 1.45903 159.832 5.88458 174.118 13.0727Z'
        {...COMMON}
        transition={{ delay: 0.5, repeat: Infinity, repeatDelay: 0.6 }}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M220.579 54.4333L167.825 84.8667C164.385 80.2625 160.293 76.1738 155.685 72.7363L186.143 20.0253C199.83 29.0311 211.566 40.7574 220.579 54.4333Z'
        {...COMMON}
        transition={{ delay: 0.6, repeat: Infinity, repeatDelay: 0.6 }}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M240.121 113.46H179.218C178.54 107.636 177.02 102.069 174.782 96.8833L227.538 66.45C234.732 80.7242 239.161 96.6279 240.121 113.46Z'
        {...COMMON}
        transition={{ delay: 0.7, repeat: Infinity, repeatDelay: 0.6 }}
      />
    </motion.svg>
  );
};

export default LogoGraySpinner;
