import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebsiteLocale } from '@wbk/hooks';
import { createCookie } from '@wbk/utils';
import Context from './context';

type Props = {
  children: React.ReactNode;
};

const LocalizationProvider = ({ children }: Props) => {
  const { i18n } = useTranslation('common');
  const { shortLang } = useWebsiteLocale();
  const [state, setState] = useState({ locale: shortLang });

  useEffect(() => {
    if (shortLang) {
      setLocale(shortLang);
    }
    // eslint-disable-next-line
  }, []);

  const setLocale = async (language: string) => {
    await i18n.changeLanguage(language);
    setState((prevState) => ({ ...prevState, locale: language }));
    createCookie({ name: 'lang', value: language, expiration: 30 });
    document.documentElement.lang = language;
    document.documentElement.dir = language.startsWith('ar') ? 'rtl' : 'ltr';
  };

  return (
    <Context.Provider
      value={{
        ...state,
        setLocale,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default LocalizationProvider;
