import { usePage } from '@wbk/contentful/api';
import { useParams } from 'react-router-dom';
import PageSection from '@/components/section';
import FullpageAnimatedLogo from '@/components/ui/Loading/FullpageAnimatedLogo';
import Seo from '@/components/headers/seo';
import Error404 from '@/components/error/404';

const Home = () => {
  const { lang } = useParams<{ lang: Language }>();
  const { data: home, isLoading, error } = usePage({ lang, limit: 1, where: { slug: 'home' } });

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!home || error) {
    return <Error404 />;
  }

  return (
    <>
      <Seo
        title={home.seo?.title || home.title}
        description={home.seo?.description}
        image={home.seo?.image?.url}
      />

      {home?.sectionsCollection.items.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </>
  );
};

export default Home;
