import LogoGraySpinner from '../spinners/LogoGraySpinner';

const FullpageAnimatedLogo = () => {
  return (
    <>
      <style>{'body{overflow:hidden}'}</style>
      <div className='bg-body fixed z-50 flex h-[calc(100svh)] w-full items-center justify-center overflow-hidden rounded-md py-20'>
        <LogoGraySpinner className='h-28 w-28' />
      </div>
    </>
  );
};

export default FullpageAnimatedLogo;
