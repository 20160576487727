import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryProvider } from '@wbk/api/hooks';
import { router } from '@/routes';
import '@/i18n/config';
import LocalizationProvider from './context/localization/provider';
import '@/styles/index.css';
import '../api.config';
import '../contentful.config';
import '../logger.config';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryProvider>
      <LocalizationProvider>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </QueryProvider>
  </React.StrictMode>
);
