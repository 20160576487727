import { useParams } from 'react-router-dom';
import { ContentfulLink } from '@wbk/contentful/api';
import { ContentfulButton } from '@wbk/contentful';

type Props = {
  link: ContentfulLink;
  onClick: () => void;
};

const NavLink = ({ link, onClick }: Props) => {
  const { slug } = useParams<{ slug: string }>();
  const isActive = slug === link.href;

  return (
    <ContentfulButton
      link={link}
      className={`group whitespace-nowrap p-1 transition ${
        link.shape === 'text'
          ? 'hover:text-primary focus:text-primary flex flex-col items-center gap-px focus:outline-none'
          : 'w-full max-w-sm'
      } ${link.shape === 'text' && isActive ? 'text-primary' : ''}`}
      onClick={onClick}
      data-title={link.label}
      data-location='header'
      role='menuitem'
    >
      {link.shape === 'text' && (
        <div
          className={`group-hover:bg-primary h-0.5 overflow-hidden transition-all ${isActive ? 'bg-primary w-full' : 'bg-text w-0 group-hover:w-full'}`}
        ></div>
      )}
    </ContentfulButton>
  );
};

export default NavLink;
