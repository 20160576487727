import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsProvider } from '@wbk/analytics';
import { AuthProvider } from '@wbk/auth';
import { ContentfulProvider } from '@wbk/contentful';
import { UiProvider } from '@wbk/ui';
import Layout from './components/layout';
import ErrorBoundary from './components/error/ErrorBoundary';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from './constants/fallbackLogo';
import ScrollToTop from './components/common/ScrollToTop';
import useLocalization from './context/localization/useLocalization';
import CookieConsent from './components/common/CookieConsent';
import { gtmTracker } from '../trackers.config';

type Props = {
  type?: LayoutType;
};

const AppLayout = ({ type }: Props) => {
  const { locale } = useLocalization();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <ErrorBoundary>
      <UiProvider
        locale={locale}
        i18n={{ useTranslation, Trans }}
        imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}
      >
        <AnalyticsProvider trackers={[gtmTracker]} currentLocation={pathname}>
          <AuthProvider
            autoInit={false}
            appSource={process.env.VITE_PUBLIC_APP_SOURCE}
            locale={locale}
            i18n={{ useTranslation, Trans }}
            router={{
              Link,
              push: navigate,
              replace: (u: string) => navigate(u, { replace: true }),
            }}
          >
            <ContentfulProvider
              locale={locale}
              i18n={{ useTranslation, Trans }}
              router={{
                Link,
                push: navigate,
                replace: (u: string) => navigate(u, { replace: true }),
              }}
            >
              <ScrollToTop />
              <Layout type={type} />
              <CookieConsent />
            </ContentfulProvider>
          </AuthProvider>
        </AnalyticsProvider>
      </UiProvider>
    </ErrorBoundary>
  );
};

export default AppLayout;
