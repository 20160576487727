import { RouteObject } from 'react-router-dom';
import Error404 from '@/components/error/404';
import TermsAndConditionsPage from '@/pages/terms';
import PrivacyPage from '@/pages/privacy';

export const termsRoutes: RouteObject[] = [
  {
    path: 'terms',
    errorElement: <Error404 />,
    element: <TermsAndConditionsPage />,
  },
  {
    path: 'privacy',
    errorElement: <Error404 />,
    element: <PrivacyPage />,
  },
];
