import { WebsiteConfig } from '@wbk/contentful/api';
import Copyright from './Copyright';

type Props = {
  config: WebsiteConfig;
};

const MiniFooter = ({ config }: Props) => {
  return (
    <footer className='bg-body-level-1 p-4'>
      <div className='container mx-auto flex flex-wrap items-center justify-center gap-4'>
        <Copyright text={config.footer.copyrightText} />
      </div>
    </footer>
  );
};

export default MiniFooter;
