import { Link } from 'react-router-dom';
import { ContentfulButton, ContentfulMedia } from '@wbk/contentful';
import { WebsiteConfig } from '@wbk/contentful/api';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import SideMenu from './SideMenu';

type Props = {
  config: WebsiteConfig;
};

const Header = ({ config }: Props) => {
  return (
    <header className='absolute z-50 w-full pt-2'>
      <nav className='container grid grid-cols-3 items-center'>
        <SideMenu config={config} />

        <div className='h-[100px] place-self-center'>
          <Link
            to='/'
            className='relative block h-full shrink-0 hover:opacity-90'
            data-location='header'
            role='menuitem'
          >
            <ContentfulMedia
              media={config.miniLogo}
              className='h-full object-contain'
              alt={config.title}
            />
          </Link>
        </div>

        <ul className='flex flex-wrap items-center justify-end gap-1 xl:gap-4' role='menu'>
          <li role='presentation' className='text-sm'>
            <LanguageSwitch />
          </li>
          {config.header.topSubNavigationCollection.items.map((link) => {
            if (!link) return null;
            return (
              <li key={link.id} className='hidden shrink-0 text-sm sm:block'>
                <ContentfulButton link={link} data-location='header' data-category={link.label} />
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
