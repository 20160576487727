import { Fragment, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWebsiteConfig } from '@wbk/contentful/api';
import useLocalization from '@/context/localization/useLocalization';
import Seo from '../headers/seo';
import FullpageAnimatedLogo from '../ui/Loading/FullpageAnimatedLogo';
import Footer from './footer';
import Header from './header';

type Props = {
  type?: LayoutType;
};

const Layout = ({ type = 'main' }: Props) => {
  const { t } = useTranslation('common');
  const { locale } = useLocalization();
  const { data } = useWebsiteConfig({ lang: locale, slug: 'config' });

  return (
    <Fragment>
      {data && (
        <Seo
          title={t('common:seo.title')}
          description={t('common:seo.description')}
          keywords={t('common:seo.keywords')}
          image={data.seo.image.url}
        />
      )}

      <Suspense>{data && <Header config={data} />}</Suspense>
      <main id='main'>
        <Suspense fallback={<FullpageAnimatedLogo />}>
          <Outlet />
        </Suspense>
      </main>
      <Suspense>{data && <Footer config={data} type={type} />}</Suspense>
    </Fragment>
  );
};

export default Layout;
