import { WebsiteConfig } from '@wbk/contentful/api';
import MainFooter from './Main';
import MiniFooter from './Mini';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
};

const Footer = ({ type = 'main', config }: Props) => {
  return type === 'mini' ? <MiniFooter config={config} /> : <MainFooter config={config} />;
};

export default Footer;
