import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentfulRichText } from '@wbk/contentful';
import { useGetQaList } from '@wbk/contentful/api';
import Error404 from '@/components/error/404';
import FullpageAnimatedLogo from '@/components/ui/Loading/FullpageAnimatedLogo';
import Seo from '@/components/headers/seo';

const PrivacyPage = () => {
  const { t } = useTranslation('common');
  const { lang } = useParams<{ lang: Language }>();
  const { data, isLoading, error } = useGetQaList({
    lang,
    where: { slug: 'ryiadh-season-website-privacy-policy' },
    limit: 1,
  });

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (error) {
    return <Error404 />;
  }

  return (
    <>
      <Seo title={t('common:privacy_policy')} />

      <div className='relative flex h-[200px] w-full opacity-70 after:absolute after:inset-0 after:-z-10 after:bg-[url(/images/default-cover.png)] after:bg-cover after:bg-[50%_35%]'>
        <div className='absolute inset-0 z-10 bg-gradient-to-t from-black via-transparent to-black'></div>
      </div>

      <section className='container'>
        <h2 className='mb-8 text-5xl font-semibold'>{data?.title}</h2>

        {data?.qaListCollection?.items?.map((item, i) => (
          <div key={i} className='space-y-4 py-8'>
            {item.title && <h2>{item.title}</h2>}
            {item.description && <ContentfulRichText description={item.description} />}
          </div>
        ))}
      </section>
    </>
  );
};

export default PrivacyPage;
