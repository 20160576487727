import { Link } from 'react-router-dom';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { ContentfulButton, ContentfulMedia } from '@wbk/contentful';
import { WebsiteConfig } from '@wbk/contentful/api';
import useLocalization from '@/context/localization/useLocalization';
import NavLink from './NavLink';

type Props = {
  config: WebsiteConfig;
};
const SideMenu = ({ config }: Props) => {
  const { locale } = useLocalization();
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <>
      {isOpen && <style>{'body{overflow:hidden}'}</style>}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key='backdrop'
            onClick={() => toggleOpen()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='fixed inset-0 z-10 bg-black/50 backdrop-blur-lg'
          />
        )}
      </AnimatePresence>

      <motion.nav
        className='mini-scrollbar fixed top-0 z-50 h-full max-h-screen w-full overflow-x-hidden bg-[#0e0e0e] ltr:left-0 rtl:right-0'
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { width: '100%', maxWidth: 450 },
          closed: { width: 0, maxWidth: 450 },
        }}
      >
        <div className='sticky inset-x-0 top-0 z-[999] h-24 backdrop-blur-xl'></div>
        <div
          className='absolute inset-0 z-[99] h-[800px] w-[800px] -translate-x-[50%] -translate-y-[15%] opacity-10 rtl:translate-x-[50%]'
          style={{
            backgroundImage:
              'radial-gradient(at 58% 47.4%, hsl(253, 100%, 74%) 0px, transparent 50%),radial-gradient(at 41% 57%, hsl(253, 100%, 74%) 0px, transparent 50%),radial-gradient(at 43% 46%, hsl(217, 100%, 61%) 0px, transparent 50%),radial-gradient(at 58.0% 57.7%, hsl(217, 100%, 61%) 0px, transparent 50%)',
          }}
        />
        <ul
          className='container absolute left-1/2 z-[99] w-[400px] max-w-[350px] -translate-x-1/2 space-y-6 overflow-y-auto py-10 lg:w-[500px] lg:max-w-md'
          role='menu'
        >
          {config.header.topNavigationCollection.items.map((link) => {
            if (!link) return null;
            return (
              <li key={link.id} className='flex text-lg font-bold'>
                <NavLink link={link} onClick={() => toggleOpen()} />
              </li>
            );
          })}

          <li>
            <hr className='border-gray-600' />
          </li>

          <li>
            <ul className='grid grid-cols-3 gap-6'>
              {config.header.zonesCollection?.items?.map((zone) => {
                if (!zone) return null;

                return (
                  <li key={zone.slug}>
                    <Link
                      to={`/${locale}/zones/${zone.slug}`}
                      className='transition hover:grayscale-[60%]'
                      onClick={() => toggleOpen()}
                    >
                      <div className='space-y-1'>
                        <ContentfulMedia
                          media={zone?.zoneLogo}
                          alt={zone.title}
                          width={100}
                          height={100}
                          fit='pad'
                        />

                        {zone?.sponsorLogo && (
                          <ContentfulMedia
                            className='mx-auto max-h-5 w-auto'
                            media={zone?.sponsorLogo}
                            alt={zone.sponsorLogo?.title || ''}
                          />
                        )}
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>

          <li>
            <hr className='border-gray-600' />
          </li>

          {config.header.bottomNavigationCollection.items.map((link) => {
            if (!link) return null;
            return (
              <li key={link.id} className='flex text-lg font-bold'>
                <NavLink link={link} onClick={() => toggleOpen()} />
              </li>
            );
          })}
          {config.header.bottomNavigationCollection.items?.length > 0 && (
            <li>
              <hr className='border-gray-600' />
            </li>
          )}

          <li>
            <ul className='flex items-center gap-4'>
              {config.socialLinks?.iconLinksCollection?.items.map((link) => {
                if (!link) return null;
                return (
                  <li key={link.id} className='rounded-md'>
                    <ContentfulButton
                      link={link}
                      rel='noreferrer noopener'
                      className='social_media_follow overflow-hidden rounded-md !p-1'
                      data-location='header'
                      data-category={link.label}
                      rounded
                    />
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      </motion.nav>

      <button
        className='focus:oultine-none relative z-50 flex h-12 w-12 flex-col items-center justify-center space-y-[5px]'
        onClick={() => toggleOpen()}
        aria-label={isOpen ? 'Close Mobile Menu' : 'Open Mobile Menu'}
        role='menuitem'
      >
        <AnimatePresence mode='popLayout' initial={false}>
          <motion.div
            key={1}
            initial={{ rotateZ: 0, x: 0 }}
            animate={{
              rotateZ: isOpen ? -45 : 0,
              y: isOpen ? '170%' : 0,
            }}
            className='h-0.5 w-5 rounded-full bg-white'
          />
          {!isOpen && (
            <motion.div
              key={2}
              className='h-0.5 w-5 rounded-full bg-white'
              initial={{ x: '-100%', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: '-100%', opacity: 0 }}
            />
          )}
          <motion.div
            key={3}
            initial={{ rotateZ: 0 }}
            animate={{
              rotateZ: isOpen ? 45 : 0,
              y: isOpen ? '-170%' : 0,
            }}
            className='h-0.5 w-5 rounded-full bg-white'
          />
        </AnimatePresence>
      </button>
    </>
  );
};

export default SideMenu;
