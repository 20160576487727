import { useParams } from 'react-router-dom';
import { useGetZoneDetail } from '@wbk/contentful/api';
import { ZoneHeroSectionRS } from '@wbk/contentful';
import Error404 from '@/components/error/404';
import PageSection from '@/components/section';
import FullpageAnimatedLogo from '@/components/ui/Loading/FullpageAnimatedLogo';
import Seo from '@/components/headers/seo';

const ZoneDetailPage = () => {
  const { lang, slug } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetZoneDetail({
    lang,
    limit: 1,
    where: { slug },
  });
  const zone = data?.[0];

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!zone) {
    return <Error404 />;
  }

  return (
    <>
      <Seo
        title={zone?.title}
        description={zone?.description}
        image={zone?.imageBg?.url || zone?.featuredBanner?.url}
      />
      <ZoneHeroSectionRS zone={zone} />
      {zone?.webSectionsCollection?.items.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </>
  );
};

export default ZoneDetailPage;
