import { logger } from '../../logger.config';

export const getWebookRedirectUrl = (url: string) => {
  try {
    const safeUrl = url.startsWith('http')
      ? url
      : url.startsWith('/')
        ? `https://webook.com${url}`
        : `https://webook.com/${url}`;
    const urlObj = new URL(safeUrl);
    const wbkUrl = `${process.env.VITE_PUBLIC_BOOK_DOMAIN}${urlObj.pathname}${urlObj.search}`;
    return wbkUrl;
  } catch (error) {
    logger.captureException(new Error('getWebookRedirectUrlException'), { extra: { url } });
    return '/';
  }
};
