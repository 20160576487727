import { RouteObject, redirect } from 'react-router-dom';
import { getWebookRedirectUrl } from '@/utils/redirect';

const loader = ({ request }: { request: Request }) => {
  const url = getWebookRedirectUrl(request.url);
  return redirect(url);
};

export const wbkRoutes: RouteObject[] = [
  {
    path: 'event/*',
    loader,
  },
  {
    path: 'events/*',
    loader,
  },
  {
    path: 'experience/*',
    loader,
  },
  {
    path: 'experiences/*',
    loader,
  },
  {
    path: 'restaurant/*',
    loader,
  },
  {
    path: 'restaurants/*',
    loader,
  },
  {
    path: 'show/*',
    loader,
  },
  {
    path: 'shows/*',
    loader,
  },
  {
    path: 'package/*',
    loader,
  },
  {
    path: 'packages/*',
    loader,
  },
  {
    path: 'auctions/*',
    loader,
  },
  {
    path: 'season/*',
    loader,
  },
  {
    path: 'seasons/*',
    loader,
  },
  {
    path: 'organizations/*',
    loader,
  },
  {
    path: 'explore',
    loader,
  },
];
