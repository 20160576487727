import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useLocalization from '@/context/localization/useLocalization';

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
};

const Seo = ({ title, description, image }: Props) => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { locale } = useLocalization();
  const isHome = pathname?.replace(locale || '', '') === '/';

  return (
    <Helmet>
      <title>{isHome ? title : `${title ? `${title} | ` : ''} ${t('common:seo.title')}`}</title>
      <meta name='description' content={description} />

      {title && <meta property='og:title' content={title} />}

      {title && <meta name='twitter:title' content={title} />}
      {image && <meta name='twitter:image' content={image} />}
    </Helmet>
  );
};

export default Seo;
