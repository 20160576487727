import { RouteObject, createBrowserRouter, redirect } from 'react-router-dom';
import { handleInvalidLocalPath } from '@wbk/utils';
import { defaultLang, supportedLngs } from '@/i18n/config';

import Home from '@/pages/Home';
import Error404 from '@/components/error/404';
import Error500 from '@/components/error/500';
import CompetitionPage from '@/pages/rs-form';
import AppLayout from '../App';

import { zonesRoutes } from './zone';
import { termsRoutes } from './terms';
import { wbkRoutes } from './wbk';

export const router = createBrowserRouter([
  {
    path: '/:lang',
    element: <AppLayout />,
    loader: ({ params, request }) => {
      const segments = Object.values(params || {}) || [];
      const firstSegment = segments?.[0] as Language;
      const isValidLang = supportedLngs.includes(firstSegment);

      if (!isValidLang) {
        const goto = handleInvalidLocalPath({
          url: request.url,
          path: segments.join('/'),
          supportedLngs,
          defaultLang,
        });
        return redirect(goto);
      }
      return null;
    },
    children: (
      [
        {
          path: '',
          element: <Home />,
          errorElement: <Error500 />,
        },
        {
          path: 'rs-form',
          element: <CompetitionPage />,
        },
      ] as RouteObject[]
    ).concat(zonesRoutes, termsRoutes),
  },
  {
    path: '/:lang',
    errorElement: <Error404 />,
    children: wbkRoutes,
  },
  {
    path: '/',
    loader: ({ request }) => {
      const goto = handleInvalidLocalPath({
        url: request.url,
        path: '',
        supportedLngs,
        defaultLang,
      });
      return redirect(goto);
    },
  },
  {
    path: '*',
    element: <AppLayout />,
    loader: ({ params, request }) => {
      const path = params['*'];
      const firstSegment = (path?.split('/')?.[0] || '') as Language;
      const isValidLang = supportedLngs.includes(firstSegment);
      if (!isValidLang) {
        const goto = handleInvalidLocalPath({
          url: request.url,
          path: path || '',
          supportedLngs,
          defaultLang,
        });
        return redirect(goto);
      }
      return null;
    },
    children: [
      {
        path: '*',
        element: <Error404 />,
      },
    ],
  },
]);
