import { WebsiteConfig } from '@wbk/contentful/api';
import { ContentfulButton, Sponsors } from '@wbk/contentful';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import Copyright from './Copyright';

type Props = {
  config: WebsiteConfig;
};

const MainFooter = ({ config }: Props) => {
  return (
    <footer className='border-t border-white/20 pt-6'>
      <div className='container px-6 pb-6'>
        <Sponsors sponsors={config.footer.sponsorsCollection} />

        <div className='flex flex-col items-center space-y-8 py-6 xl:flex-row xl:justify-between xl:space-y-0'>
          <div className='flex grow flex-col items-center gap-4 space-y-4 lg:flex-row lg:gap-20 lg:space-y-0'>
            <figure className='shrink-0'>
              <img
                src={config.footer.logo.url}
                width={config.footer.logo.width}
                height={config.footer.logo.height}
                alt={config.title}
              />
            </figure>

            <ul className='flex shrink-0 items-center gap-4'>
              {config.socialLinks?.iconLinksCollection?.items.map((link) => (
                <li key={link.id} className='rounded-md'>
                  <ContentfulButton
                    link={link}
                    rel='noreferrer noopener'
                    className='social_media_follow overflow-hidden rounded-md !p-1'
                    data-location='footer'
                    data-category={link.label}
                    rounded
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className='flex w-full flex-col items-center justify-center gap-2 lg:flex-row lg:gap-6 xl:justify-end'>
            <p className='text-center text-sm'>{config.footer.downloadAppPrompt}</p>

            <div className='flex items-center'>
              {config.footer.mobileAppLinksCollection.items.map((link) => (
                <ContentfulButton key={link.id} link={link} />
              ))}
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-between border-t border-gray-500/50 pt-5 lg:flex-row'>
          <nav className='flex flex-wrap justify-center py-2 pb-8 text-xs'>
            <ul className='flex flex-row flex-wrap items-center justify-center gap-2 space-y-0 md:gap-12'>
              <li>
                <LanguageSwitch />
              </li>
              {config.footer.bottomLinksCollection.items.map((link) => (
                <li key={link.id}>
                  <ContentfulButton
                    link={link}
                    rel='noreferrer noopener'
                    data-location='footer'
                    data-category={link.label}
                    className='p-1 underline-offset-2 hover:underline'
                  />
                </li>
              ))}
            </ul>
          </nav>

          <Copyright text={config.footer.copyrightText} />
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
