export type ProcessedFile = File & { preview?: string; upload_id?: string };

/**
 * Resize image dropped in html input before upload to server
 *
 * @param file the dropped file
 * @param maxSize the max accepted size in bytes
 */
export const reduceImageSize = async (file: File, maxSize: number): Promise<ProcessedFile> => {
  //
  if (maxSize >= file.size) {
    const base64 = await fileToBase64(file);
    const accepted = file as ProcessedFile;
    accepted.preview = base64;
    accepted.upload_id = Math.random().toString().slice(1);
    return accepted;
  }

  // Keep reducing until be less than 500kb
  let reduced = await reduce(file);

  while (reduced.size > maxSize) {
    reduced = await reduce(reduced);
  }

  return reduced;
};

const reduce = async (file: File): Promise<ProcessedFile> => {
  const base64 = await fileToBase64(file);
  const img = new Image();
  img.src = base64;

  return new Promise((resolve, reject) => {
    img.onload = function () {
      const $this = this as GlobalEventHandlers & {
        width: number;
        height: number;
      };
      const width = $this.width;
      const height = $this.height;

      // Resize ratio 10%
      const ratio = 0.9; // Math.min(maxSize / file.size, 0.5);
      const reducedWidth = width * ratio;
      const reducedHeight = height * ratio;
      const canvas = document.createElement('canvas');
      canvas.width = reducedWidth;
      canvas.height = reducedHeight;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, reducedWidth, reducedHeight);

      canvas.toBlob(async (blob) => {
        if (!blob) {
          return reject('Cannot create image from canvas');
        }
        const accepted = new File([blob as Blob], file.name, {
          type: file.type,
        }) as ProcessedFile;
        accepted.preview = base64;
        accepted.upload_id = Math.random().toString().slice(1);
        resolve(accepted);
      }, file.type);
    };
  });
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (r) {
      const base64 = r.target?.result as string;
      resolve(base64);
    };
    reader.onerror = reject;
  });
};
