import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { createCookie } from '@wbk/utils';
import { languages } from '@/i18n/config';
import useLocalization from '@/context/localization/useLocalization';

const LanguageSwitch = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('common');
  const { locale, setLocale } = useLocalization();

  const handleDir = async (goto: string) => {
    try {
      setLoading(true);
      await setLocale(goto);

      createCookie({
        name: 'lang',
        value: goto,
        expiration: 30,
        domain: import.meta.env.VITE_COOKIE_DOMAIN,
      });
    } catch (error) {
      // under investigation if really helpful or not
      // window.location.reload();
    } finally {
      setLoading(false);
    }
  };
  const otherLang = useMemo(() => {
    return languages.filter((l) => l !== locale);
  }, [locale]);

  return (
    <Button
      shape='text'
      theme='white'
      className='gap-1 py-1 uppercase underline-offset-2 hover:underline'
      onClick={() => handleDir(otherLang[0])}
      data-testid='lang-switcher'
      data-location='header'
      data-target-language={otherLang[0]}
      role='menuitem'
      loading={loading}
    >
      <span className='ltr:font-vazirmatn rtl:font-gellix hidden sm:block'>
        {t(`common:${otherLang[0]}`)}
      </span>
      <span className='ltr:font-vazirmatn rtl:font-gellix sm:hidden'>
        {t(`common:${otherLang[0]?.split('-')[0]}`)}
      </span>
    </Button>
  );
};

export default LanguageSwitch;
