import { createContext } from 'react';

type Props = {
  locale: string;
  setLocale: (locale: string) => Promise<void>;
};

const LanguageContext = createContext<Props>({
  locale: '',
  setLocale: async () => {},
});

export default LanguageContext;
